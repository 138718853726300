.shell-sidebar {
	background: #0c2461 !important;
	max-height: 105vh !important;
	min-height: 100vh !important;

	li.ant-menu-item.ant-menu-item-only-child {
		margin-top: 0 !important;
		margin-bottom: 0 !important;

		a {
			color: #e1f3ff !important;
		}

		a:hover {
			color: #6b93ce !important;
		}
	}
	.ant-menu-submenu-title {
		color: #6b93ce !important;
		font-weight: bold !important;
		font-size: 11px !important;
		text-transform: uppercase !important;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.ant-menu-submenu > .ant-menu,
	li.ant-menu-item,
	.ant-menu {
		font-family: 'Inter', sans-serif !important;
		font-size: 12px !important;
		background: transparent !important;
		color: #e1f3ff !important;

		:hover {
			color: #fafafa !important;
		}
	}

	li.ant-menu-item {
		&:hover {
			.ant-menu-submenu-arrow {
				&::before {
					background: #e1f3ff !important ;
				}
				&::after {
					background: #e1f3ff !important ;
				}
			}
		}
	}

	i.ant-menu-submenu-arrow {
		color: #e1f3ff;
	}

	.ant-menu-submenu-arrow {
		&::before {
			&:hover {
				background: #e1f3ff !important ;
			}

			background: #e1f3ff !important ;
		}
		&::after {
			&:hover {
				background: #e1f3ff !important ;
			}
			background: #e1f3ff !important ;
		}
	}
	.ant-menu-inline {
		border: none !important;
	}
}

.shell-sidebar-content {
	width: 230px !important;
	min-width: 230px !important;
	max-width: 230px !important;
}
